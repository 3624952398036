import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.regexp.replace";
import axios from 'axios';
import { Message } from 'element-ui';
import { getToken } from '@/utils/auth';
import store from '@/store';
import router from '@/router';
import { sleep } from '@/utils/sleep';
import Finger from '@/utils/fingerprintjs2';
import encrypt from "./encrypt";
Finger.load();
window.encrypt = encrypt;

// 创建 axios 实例
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30 * 1000,
  // 请求超时
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'X-Request-Sys': '8',
    lang: 'zh_CN',
    'zr-encrypted': false
  }
});

// 请求拦截器
service.interceptors.request.use(function (config) {
  // 在发送请求之前做一些事情
  // console.log(getToken(), store.getters.token, 'token')

  var path = config.url.replace(config.baseURL, '');
  if (['/domain/updateAutoCheckStatusById'].includes(path)) {
    config.timeout = 60 * 1000;
  }
  if (store.getters.token) {
    config.headers['X-Request-Token'] = getToken();
    // config.headers['X-Request-Sys'] = '1'
    // config.headers['X-Request-Sys'] = '8'
    // config.headers['Content-type'] = 'application/json; charset=utf-8'
  }
  config.headers['X-Request-Browser'] = Finger.get();
  config.headers['platform'] = 'h5';
  config.headers['application'] = 'domain-manager';
  config.headers['version'] = 'v1.0.0';

  // console.log('process.env.VUE_APP_ENCRYOTED  :>> ', process.env.VUE_APP_ENCRYOTED)
  if (process.env.VUE_APP_ENCRYOTED === 'on') {
    config.headers['X-Response-Encrypted'] = true;
    config.headers['zr-encrypted'] = true;
    config.headers['keyid'] = process.env.VUE_APP_KEY_ID;
    if (config.data && !config.data.append) {
      return encrypt.encryptData(config);
    }
  } else {
    config.headers['X-Response-Encrypted'] = false;
    config.headers['zr-encrypted'] = false;
  }
  return config;
}, function (error) {
  console.log(error); // for debug
  return Promise.reject(error);
});

// 响应拦截器
// service.interceptors.response.use(
// 	/**
// 	 * 如果你想获取 HTTP 信息，例如 headers 或 status
// 	 * 请 return response => response
// 	 */

// 	/**
// 	 * 通过自定义代码确定请求状态
// 	 * 这里只是一个例子
// 	 * 也可以通过HTTP Status Code判断状态
// 	 */
// 	(response) => {
// 		if (response.code === 401) {
// 			Message({
// 				message: '登录失败，请重新登录',
// 				type: 'error'
// 			})
// 			store.dispatch('user/logout')
// 		}
// 		const res = response.data
// 		// if the custom code is not 20000, it is judged as an error.
// 		return res
// 	},
// 	(error) => {
// 		if (error + '' === 'Error: Request failed with status code 401') {
// 			Message({
// 				message: '登录失败，请重新登录',
// 				type: 'error'
// 			})
// 			store.dispatch('user/logout')
// 		}
// 		Message({
// 			message: error.message,
// 			type: 'error',
// 			duration: 5 * 1000
// 		})
// 		return Promise.reject(error)
// 	}
// )

service.interceptors.response.use(
/**
 * 如果你想获取 HTTP 信息，例如 headers 或 status
 * 请 return  response => response
 */

/**
 * 通过自定义代码判断请求状态
 * 这里只是一个例子
 * 也可以通过HTTP Status Code判断状态
 */
function (response) {
  // console.log('response :', response)
  var headers = response.config.headers;
  // 数据解密, 只对content-type为application/json或者text/plain解密
  var headersContentType = response.headers['content-type'] || '';
  if (headers['zr-encrypted'] && response.data && (headersContentType.includes('application/json') || headersContentType.includes('text/plain'))) {
    var decryptData = encrypt.decryptData(response.data) || '';
    response.data = decryptData ? JSON.parse(decryptData) : {};
  }
  var res = response.data;
  // console.log('request===>', res)
  if (res.code !== 200) {
    Message({
      message: res.msg || res.message || 'Error',
      type: 'error',
      duration: 5 * 1000
    });
    switch (res.code) {
      // 您长时间没有操作，为保证安全使用，请重新登录
      case 20000:
      case 20001:
        sleep(1000).then(function () {
          store.dispatch('user/logout').then(function (_) {
            location.reload();
          }).catch(function (err) {
            Message({
              message: err.message,
              type: 'error'
            });
            router.go('/');
          });
        });
        break;
    }
    return Promise.reject(res);
  } else {
    return res;
  }
}, function (error) {
  console.log('err' + error); // 用于调试
  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;